<template>
<v-container style="max-width:700px; margin:auto">
    <form @submit.prevent="submit">
        <v-row dense class="p-relative zindex-1">
            <v-col class="mb-6" cols="12" align="center" justify="center" v-if="$vuetify.breakpoint.mdAndUp">
                <h3 class="heading--text">My Details</h3>
                <p class="muted-1 ma-0">Fill out the form below to update your user details.</p>
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field ref="firstName" v-model="firstName" :disabled="loading" required label="First name" :rules="[rules.required]" class="pt-0 mt-0" color="dark" outlined placeholder="Enter your first name...">
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field ref="lastName" v-model="lastName" :disabled="loading" required label="Last name" :rules="[rules.required]" class="pt-0 mt-0" color="dark" outlined placeholder="Enter your last name...">
                </v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field ref="email" v-model="email" type="email" :disabled="loading" label="Email Address" required :rules="[rules.required, rules.email]" class="pt-0 mt-0" outlined color="dark" placeholder="Enter your email...">
                </v-text-field>
            </v-col>
            <v-col cols="12">
                <v-autocomplete ref="country" v-model="country" :disabled="loading" label="Country" :items="countries" item-text="name" item-value="code" class="pt-0 mt-0" outlined color="dark" placeholder="Select your country..." return-object>
                    <template v-slot:item="{ item }">
                        <v-icon class="mr-4">{{item.emoji}}</v-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <template v-slot:selection="{ item }">
                        <v-icon class="mr-4">{{item.emoji}}</v-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" :class="{ 'd-flex justify-center' :$vuetify.breakpoint.mdAndUp }">
                <v-btn x-large color="dark" dark type="submit" :loading="loading">
                    Save details
                    <v-icon color="warning" class="ml-2">mdi-content-save-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </form>
</v-container>
</template>

<script>
// Services
import Contact from '@/modules/meo-module/contact';
import Persona from '@/modules/meo-module/persona';
import Countries from '@/modules/countries';

export default {
    name: 'profile-details',
     meta: {
        titleTemplate: "Details |  %s"
    },

    data() {
        return {
            loading: false,
            error: false,
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            country: {},
            showPassword: false,
            rules: {
                required: value => !!value || 'This field is required',
                minLength3: value => value.length >= 3 || 'Min 3 characters',
                minLength4: value => value.length >= 4 || 'Min 4 characters',
                minLength6: value => value.length >= 6 || 'Min 6 characters',
                isMatch: value => value === this.confirmPassword,
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail'
                },
            }
        }
    },

    computed: {
        countries() {
            return Object.keys(Countries).map(key => {
                return {
                    name: Countries[key].name,
                    code: key,
                    emoji: Countries[key].emoji,
                    unicode: Countries[key].unicode,
                    image: Countries[key].image
                };
            });
        }
    },

    methods: {
        async init() {
            this.$meo.loading = true;

            this.$fluro.resetCache();
            this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
            await new Promise((resolve) => setTimeout(resolve, 100));

            this.$fluro.resetCache();
            this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
            await new Promise((resolve) => setTimeout(resolve, 100));

            this.$fluro.resetCache();
            this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
            await new Promise((resolve) => setTimeout(resolve, 100));

            this.$fluro.resetCache();
            this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
            await new Promise((resolve) => setTimeout(resolve, 100));

            let user = await this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);

            this.$fluro.auth.sync();

            let contactId = user.contacts && user.contacts[0];

            let result = await Contact.get(contactId).then(({ data }) => data);

            if (result && result.data && result.data.country && result.data.country.code) {
                this.country = result.data.country;
            }

            if (user) {
                this.firstName = user.firstName;
                this.lastName = user.lastName;
                this.email = user.email;
            }

            this.$meo.loading = false;
        },
        async submit() {
            this.loading = true;

            let user = this.$meo.user;
            let contact = user.contacts;

            if (contact && contact.length) {
                let contactId = contact[0];

                let contactPayload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    emails: [this.email],
                    data: {
                        country: this.country
                    }
                };

                let updatedContact = await Contact.update(contactId, contactPayload).then(({ data }) => data);
                console.log("CONTACT", updatedContact);
            }

            if (user) {
                let personaPayload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    username: this.email
                };

                let updatedPersona = await Persona.update(user.persona, personaPayload).then(({ data }) => data);
                console.log("PERSONA", updatedPersona);

                this.$fluro.resetCache();
                this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
                await new Promise((resolve) => setTimeout(resolve, 100));

                this.$fluro.resetCache();
                this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
                await new Promise((resolve) => setTimeout(resolve, 100));

                this.$fluro.resetCache();
                this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
                await new Promise((resolve) => setTimeout(resolve, 100));

                this.$fluro.resetCache();
                this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);
                await new Promise((resolve) => setTimeout(resolve, 100));

                await this.$fluro.api.get('/session', { cache: false }).then(({ data }) => data);

                this.$fluro.auth.sync();
            }

            this.loading = false;
        }
    },

    async mounted() {
        await this.init();

        if (!this.firstName || !this.firstName.length) {
            return this.$refs.firstName.focus()
        }

        if (!this.lastName || !this.lastName.length) {
            return this.$refs.lastName.focus()
        }

        if (!this.email || !this.email.length) {
            return this.$refs.email.focus()
        }
    },
}
</script>

<style lang="scss">
</style>
